@font-face {
  font-family: 'Gianpiero 1';
  src: url('./fonts/Gianpiero_1.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  padding-right: 0%;
  padding-left: 0%;
  background-color: #07081b;
  background-image: url('./images/Background-No-Cloud-2600.png');
  background-position: 50% 0%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  font-family: 'Gianpiero 1', sans-serif;;
  text-align: right;
}


@media screen and (max-width: 500px) {
  body {
      background-image: url('images/Background-No-Cloud-500.png');
  }
}
@media screen and (min-width:500px) and (max-width: 800px) {
  body {
      background-image: url('images/Background-No-Cloud-800.png');
  }
}
@media screen and (min-width:800px) and (max-width: 1080px) {
  body {
      background-image: url('images/Background-No-Cloud-1080.png');
  }
}
@media screen and (min-width:1080px) and (max-width: 1600px) {
  body {
      background-image: url('images/Background-No-Cloud-1600.png');
  }
}
@media screen and (min-width:1600px) and (max-width: 2000px) {
  body {
      background-image: url('images/Background-No-Cloud-2000.png');
  }
}
