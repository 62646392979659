.App {
  margin: 0px auto;
  box-sizing: border-box
}

.image-35 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: -1;
}
.image-36 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
@media screen and (min-width: 1440px) {
  .image-36 {
    max-height: 143px;
  }
}
@media screen and (max-width: 991px) {
  .image-35 {
    min-height: 140px;
  }
}
@media screen and (max-width: 479px) {
  .image-35 {
    overflow: hidden;
    max-width: 200%;
    min-height: 120px;
  }
  .image-36 {
    margin-bottom: 5px;
  }
}
/* MAIN CARDS WRAPPER START */

.main-card-wrapper,
.main-card__inner-wrapper {
  display: flex;
  box-sizing: border-box;
  border-radius: 10px;
}

.main-card-wrapper {
  min-height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 5vh;
}
.main-card__inner-wrapper {
  flex-flow: column;
  background: linear-gradient(90deg, wheat 0%, #f5deb3cc 50%, #f5deb300 100%);
  width: 100%;
  padding: 100px 5vw;
}

.header-txt {
  color: #404040;
  font-size: 4em;
  max-width: 350px;
  line-height: 1em;
  margin: 0px;
}


/* MAIN CARDS WRAPPER END */



/* CARDS SECTION START */
.cards-wrapper {
  width: 100%;
  margin: 100px auto;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(300px, 1fr));
  box-sizing: border-box;
}

.cards-item {
  max-width: 300px;
  margin: 0px auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 30px;
  box-sizing: border-box;
  grid-gap: 10px;
}

.img-wrapper,
.img-wrapper>img {
  width: 100%;
  height: 100%;
}
/* CARDS SECTION END */


/* BUTTONS START */
.main-mint-btn {
  cursor: pointer;
  border-radius: 7px;
  padding: 7px 30px;
  border: 2px solid #404040;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  max-width: 350px;
  background-color: #82b8ca;
}

.main-mint-btn:disabled,
.sm-mint-button:disabled {
  opacity: .5;
  background-color: #bdbdbd;
}
.sm-mint-button {
  background-color: #fff;
  padding: 5px 20px;
  border-radius: 20px;
  cursor: pointer;
}
.main-mint-btn:hover,
.sm-mint-button:hover {
  background-color: #a2e5f4;
  font-weight: bold;
}

/* BUTTONS END */